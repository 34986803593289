import { checkAccountUserRelation } from '@/api/accounts/account';
import { getSlimAccounts } from '@/api/accounts/accounts';
import type { TopLevelRoutePermission } from '@/api/interfaces/definitions/permission';
import type { UserAccount } from '@/api/interfaces/user/user-account';
import { useSharedAddressIndexStore } from '@/components/Address/stores/useAddressIndexStore';
import { setLanguage } from '@/helpers/i18n';
import { useMoment } from '@/helpers/moment';
import { useNavigation } from '@/helpers/navigation/navigation';
import { keycloakInstance, useDeepSso } from '@/plugins/keycloak';
import store from '@/store';
import { useLanguagesStore } from '@/stores/basics/languages';
import { useJournalYearsStore } from '@/stores/definitions/journal-years';
import { useModulesStore } from '@/stores/definitions/modules';
import { usePermissionsStore } from '@/stores/definitions/permissions';
import { useTaxTimelinesStore } from '@/stores/tax/tax-timelines';
import { useNotificationStore } from '@/stores/user-interface/notification';
import { useUserStore } from '@/stores/users/user';
import { Validator } from 'vee-validate';
import Vue from 'vue';
import VueRouter, { type RouteConfig } from 'vue-router';
import ababank from './ababank';
import accounting from './accounting';
import address from './address';
import admin from './admin';
import companySwitch from './company-switch';
import customers from './customers';
import dashboard from './dashboard';
import deepv from './deepv';
import documents from './documents';
import finances from './finances';
import inbox from './inbox';
import products from './products';
import reports from './reports';
import settings, {
  agbRouteName,
  confirmRouteName,
  flexibleAccountRegistrationRouteName,
  registrationRouteName,
} from './settings';
import suppliers from './suppliers';
import support from './support';
import timeTracking from './time-tracking';

const ErrorPage = () => import('@/pages/Error.vue');
const EmployeeError = () => import('@/pages/ErrorEmployee.vue');
const ErrorNoPermission = () => import('@/pages/ErrorNoPermission.vue');
const OAuthCb = () => import('@/pages/OAuthCb.vue');
const MigrationForm = () => import('@/pages/swiss21/MigrationForm.vue');

const firstRouteName = 'oauthcb';
const noPermissionRouteName = 'error-no-permission';
const notFoundRouteName = 'error-page';
const swiss21MigrationRouteName = 'swiss21';
let isDeepSSO = false;

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'index',
    redirect: { name: 'dashboard' },
  },
  {
    path: `/${firstRouteName}`,
    name: firstRouteName,
    component: OAuthCb,
    // redirect: { name: localStorage.getItem('requestedRoute') || 'dashboard', query: localStorage.getItem('requestedQuery') || '' },
  },
  {
    path: '/error',
    name: notFoundRouteName,
    component: ErrorPage,
    meta: {
      title: 'page_not_found_title',
    },
  },
  {
    path: '/no-permission',
    name: noPermissionRouteName,
    component: ErrorNoPermission,
  },
  {
    path: '/employee_error',
    name: 'employee_error',
    component: EmployeeError,
    meta: {
      title: 'error_employee_missing_title',
    },
  },
  {
    path: '/swiss21/migration',
    name: swiss21MigrationRouteName,
    component: MigrationForm,
    meta: {
      title: 'swiss21_migration',
    },
  },
  {
    path: '/user',
    name: 'SettingsUser',
    redirect: '/v3/settings/user/userdata',
  },
  ababank,
  address,
  customers,
  suppliers,
  ...documents,
  finances,
  products,
  accounting,
  timeTracking,
  admin,
  ...settings,
  deepv,
  inbox,
  ...support,
  reports,
  dashboard,
  companySwitch,

  {
    path: '/services/abanet/cb',
    redirect: (to) => ({ path: '/v3/services/abanet/cb', query: to.query, hash: to.hash }),
  },

  {
    path: '/settings/company/modules',
    redirect: (to) => ({ path: '/v3/settings/company/modules', query: to.query, hash: to.hash }),
  },

  {
    path: '/oauth/cb',
    redirect: (to) => ({ path: '/v3/oauth/cb', query: to.query, hash: to.hash }),
  },

  {
    path: '/v3/:pathMatch(.*)*',
    name: 'redirectToV3',

    beforeEnter: (to) => {
      const redirectUrl = new URL(to.fullPath.replace('/v3', import.meta.env.VITE_NINJA_BASE_PATH), useDeepSso
        ? import.meta.env.VITE_NINJABOX_FRONTEND_URL
        : import.meta.env.VITE_NINJA_BASE_URL);

      // redirect to ninja v3
      window.location.assign(redirectUrl.href);

      return false;
    },
  },

  {
    path: '*',
    redirect: { name: notFoundRouteName },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  linkActiveClass: 'current',
  routes,
  scrollBehavior(to) {
    if (to.meta?.savePosition) {
      return null;
    }
    return { x: 0, y: 0 };
  },
});

const permittedTopLevelRoutes = [
  firstRouteName,
  noPermissionRouteName,
  notFoundRouteName,
  'SettingsUser',
  'companies-switch',
  'supportinfo',
  'ababank',
  'inbox',
  'admin',
];

const finalRoutes = [
  registrationRouteName,
  confirmRouteName,
  agbRouteName,
  'companies-switch',
];

const permittedRoutes = [
  ...finalRoutes,
  flexibleAccountRegistrationRouteName,
];

const swiss21ExcludedRoutes = [
  ...finalRoutes,
  swiss21MigrationRouteName,
];

async function getSessionUserAccount() {
  const userAccounts = (await getSlimAccounts()).data.data;

  const sessionUuid = sessionStorage.getItem('accountUuid');
  const storageUuid = localStorage.getItem('accountUuid');
  const sessionAppInstanceUuid = sessionStorage.getItem('appInstanceUuid');
  const storageAppInstanceUuid = localStorage.getItem('appInstanceUuid');
  const predefinedUuid = sessionUuid || storageUuid;
  const predefinedAppInstanceUuid = sessionAppInstanceUuid || storageAppInstanceUuid;
  let appInstanceAccount: UserAccount | undefined;
  let predefinedAccount: UserAccount | undefined;

  if (predefinedAppInstanceUuid) {
    appInstanceAccount = userAccounts
      .find((account) => account.app_instance_uuid === predefinedAppInstanceUuid);
    sessionStorage.removeItem('appInstanceUuid');
  }

  if (predefinedUuid) {
    predefinedAccount = userAccounts.find((account) => account.uuid === predefinedUuid);
  }

  if (isDeepSSO && predefinedUuid && predefinedUuid !== 'undefined' && !predefinedAccount) {
    try {
      const response = await checkAccountUserRelation(predefinedUuid);
      predefinedAccount = response.data.data;
    } catch (error) {
      useNotificationStore().fromAxiosError(error);
    }
  }

  const sessionUserAccount: UserAccount | undefined = userAccounts.length ? appInstanceAccount
    || predefinedAccount
    || userAccounts.find((account) => account.current_selected)
    || userAccounts.find((account) => account.is_owner)
    || userAccounts[0] : undefined;

  if (sessionUserAccount) {
    sessionStorage.setItem('accountUuid', sessionUserAccount.uuid);
    localStorage.setItem('accountUuid', sessionUserAccount.uuid);

    if (sessionUserAccount.app_instance_uuid) {
      sessionStorage.setItem('appInstanceUuid', sessionUserAccount.app_instance_uuid);
      localStorage.setItem('appInstanceUuid', sessionUserAccount.app_instance_uuid);
    }

    Validator.localize(sessionUserAccount.locale);
    setLanguage(sessionUserAccount.locale);
    useMoment().locale(sessionUserAccount.locale);
  }

  return sessionUserAccount;
}

async function init() {
  isDeepSSO = !!keycloakInstance.authServerUrl && keycloakInstance.authServerUrl.includes('deepcloud.swiss');
  const userStore = useUserStore();
  const sharedAddressIndexStore = useSharedAddressIndexStore();

  if (!userStore.hasLoaded) {
    try {
      const sessionUserAccount: UserAccount | undefined = await getSessionUserAccount();

      if (!sessionUserAccount && import.meta.env.VITE_ENVIRONMENT === 'local') {
        return;
      }

      if (isDeepSSO && sessionUserAccount) {
        try {
          await checkAccountUserRelation(sessionUserAccount.uuid);
        } catch {
          useNotificationStore().showNotification({
            type: 'error',
            message: 'Access has been removed',
          });
        }
      }

      if (!sessionUserAccount || (!sessionUserAccount.uuid && import.meta.env.VITE_ACCOUNT_CREATION_ENABLED === 'false')) {
        if (isDeepSSO) {
          window.location.replace(import.meta.env.VITE_DEEPBOX_URL);
        } else {
          window.location.replace('/swiss21-account-required');
        }
      } else {
        await store.dispatch('definitions/account/fetchAccount', {
          accountUuid: sessionUserAccount.uuid,
        });

        await Promise.all([
          userStore.requireEntity(),
          usePermissionsStore().getEntity(),
          useModulesStore().requireEntities(),
          useTaxTimelinesStore().requireEntities(),
          useJournalYearsStore().requireEntities(),
          useLanguagesStore().requireEntities(),
          sharedAddressIndexStore.getEntity().then((response) => {
            if (!response.data.data.indexingStartedAt) {
              sharedAddressIndexStore.createEntity();
            }
          }),
          store.dispatch('accounting/fetchAccountingReady'),
        ]);
      }
    } catch (error) {
      useNotificationStore().fromAxiosError(error);
    }
  }
}

router.beforeEach(async (to, from, next) => {
  try {
    await init();

    if (!to.matched.length) {
      next();
      return;
    }

    const topLevelRoutePermission = to.matched.find((match) => !!match.name)?.name as TopLevelRoutePermission;
    const toRouteName = to.matched.length ? to.matched[to.matched.length - 1].name : null;
    const { account } = store.state.definitions.account;

    if (!account.has_wizard_completed && toRouteName && !finalRoutes.includes(toRouteName)) {
      next({ name: registrationRouteName });
      return;
    }

    if ((account.needs_confirmation || account.needs_acc_framework_update) && toRouteName
      && !finalRoutes.includes(toRouteName)) {
      next({ name: confirmRouteName });
      return;
    }

    if (!account.has_all_agb_accepted && toRouteName && !finalRoutes.includes(toRouteName)) {
      next({ name: agbRouteName });
      return;
    }

    if (!account.is_swiss21_account && !account.is_deepbox_account
      && toRouteName && !swiss21ExcludedRoutes.includes(toRouteName)
    ) {
      next({ name: swiss21MigrationRouteName });
      return;
    }

    if (permittedTopLevelRoutes.includes(topLevelRoutePermission)
      || (toRouteName && permittedRoutes.includes(toRouteName))) {
      next();
      return;
    }

    const permissionsStore = usePermissionsStore();
    const hasTopLevelRoutePermission = topLevelRoutePermission
      && permissionsStore.hasTopLevelRoutePermission(topLevelRoutePermission)
      && permissionsStore.hasRoutePermission(topLevelRoutePermission);

    const hasRoutePermission = toRouteName && permissionsStore.hasRoutePermission(toRouteName);

    if ((!hasTopLevelRoutePermission || !hasRoutePermission) && toRouteName !== 'redirectToV3') {
      if (from.name === firstRouteName) {
        next({ name: useNavigation(to.matched).value[0].route });
      } else {
        next({
          name: noPermissionRouteName,
          query: {
            grantedPermissions: permissionsStore.grantedPermissions,
            missingPermissions: permissionsStore.missingPermissions(topLevelRoutePermission, toRouteName),
          },
        });
      }
    } else {
      next();
    }
  } catch {
    next({ name: notFoundRouteName });
  }
});

export default router;
