import type {
  Permission,
  Permissions,
  RoutePermission,
  TopLevelRoutePermission,
} from '@/api/interfaces/definitions/permission';
import type { Account } from '@/api/interfaces/user/account';
import { getPermissions } from '@/api/permissions';
import store from '@/store';
import { useGetEntity } from '@/stores/composables/use-get-entity';
import { useModulesStore } from '@/stores/definitions/modules';
import { useTaxTimelinesStore } from '@/stores/tax/tax-timelines';
import { useBetaFeaturesStore } from '@/stores/definitions/beta-features';
import { useUserStore } from '@/stores/users/user';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed } from 'vue';

const amidRoutes = [
  'balance-sheet',
  'opening-balance',
  'income-statement',
  'key-figures',
  'accounting-vat-report',
  'vat-journal',
  'vat-control-report',
  'vat-adjustment',
];

const deepBoxRoutes = [
  ...amidRoutes,
  'account-statement',
  'journal',
  'deepv',
  'payment-systems',
  'api-tokens',
];

const flexibleAccountingPlanRoutes = [
  'abasalary-mappings',
  'merchant-category-codes',
];

export const usePermissionsStore = defineStore('permissions', () => {
  const getEntity = useGetEntity<Permissions>(getPermissions);
  const modulesStore = useModulesStore();
  const taxTimelinesStore = useTaxTimelinesStore();
  const betaFeaturesStore = useBetaFeaturesStore();
  const userStore = useUserStore();
  const account = computed<Account>(() => store.state.definitions.account.account);
  const isEmployee = computed(() => !!userStore.entity?.employee_uuid);
  const isOwner = computed(() => !!getEntity.entity.value && getEntity.entity.value.is_owner);
  const isAdmin = computed(() => !!getEntity.entity.value && getEntity.entity.value.is_admin_group);
  const hasFullAccess = computed(() => isOwner.value || isAdmin.value);
  const isSwissAccount = computed(() => account.value.country_code === 'CH');
  const isDeepBoxAccount = computed(() => account.value.is_deepbox_account);

  const hasTaxableTaxTimelines = computed(
    () => !!taxTimelinesStore.entities.find((item) => item.method !== 'tax_free'),
  );

  const hasNonFlatTaxTimelines = computed(
    () => !!taxTimelinesStore.entities.find((item) => item.method !== 'flat_rate_method'
      && item.method !== 'tax_free'),
  );

  const hasFrameworkKmu = computed(() => account.value.accounting_framework_id === 2
    || account.value.accounting_framework_id === 5);

  const hasFlexibleAccountingPlan = computed(() => account.value.has_flexible_acc_accounts);

  const isAccountingPermitted = computed(() => ['DE', 'CH', 'LI'].includes(account.value.country_code));

  const grantedPermissions = computed<Array<Permission>>(() => {
    if (isOwner.value) {
      return ['is_owner'];
    }
    if (isAdmin.value) {
      return ['is_admin_group'];
    }
    return getEntity.entity.value ? (Object.keys(getEntity.entity.value) as Array<Permission>).filter(
      (permissionKey) => !!getEntity.entity.value && getEntity.entity.value[permissionKey],
    ) : [];
  });

  const missingPermissions = (topLevelRoutePermission: TopLevelRoutePermission, route?: string | null) => {
    const routePermissions: RoutePermission[] = !!getEntity.entity.value
      && !getEntity.entity.value[topLevelRoutePermission] ? [topLevelRoutePermission] : [];

    switch (route) {
      case 'accounting':
        if (!isAccountingPermitted.value) {
          routePermissions.push('is_accounting_permitted');
        }
        break;
      case 'accounting-report':
      case 'open-items':
        if (!(hasFrameworkKmu.value || hasFlexibleAccountingPlan.value)) {
          routePermissions.push('has_framework_kmu|has_flexible_accounting_plan');
        }
        break;
      case 'abasalary-mappings':
        if (!hasFlexibleAccountingPlan.value) {
          routePermissions.push('has_flexible_accounting_plan');
        }
        break;
      case 'balance-sheet':
      case 'opening-balance':
      case 'income-statement':
        if (!(hasFrameworkKmu.value || hasFlexibleAccountingPlan.value)) {
          routePermissions.push('has_framework_kmu|has_flexible_accounting_plan');
        }
        if (modulesStore.isAmidAccount) {
          routePermissions.push('is_not_amid_account');
        }
        if (isDeepBoxAccount.value) {
          routePermissions.push('is_not_deepbox_account');
        }
        break;
      case 'key-figures':
        if (!hasFrameworkKmu.value) {
          routePermissions.push('has_framework_kmu');
        }
        if (modulesStore.isAmidAccount) {
          routePermissions.push('is_not_amid_account');
        }
        if (isDeepBoxAccount.value) {
          routePermissions.push('is_not_deepbox_account');
        }
        if (!isSwissAccount.value) {
          routePermissions.push('is_swiss_account');
        }
        break;
      case 'accounting-vat-report':
      case 'vat-journal':
      case 'vat-control-report':
        if (!hasFrameworkKmu.value) {
          routePermissions.push('has_framework_kmu');
        }
        if (modulesStore.isAmidAccount) {
          routePermissions.push('is_not_amid_account');
        }
        if (isDeepBoxAccount.value) {
          routePermissions.push('is_not_deepbox_account');
        }
        if (!hasTaxableTaxTimelines.value) {
          routePermissions.push('has_taxable_tax_timelines');
        }
        break;
      case 'vat-adjustment':
        if (!hasFrameworkKmu.value) {
          routePermissions.push('has_framework_kmu');
        }
        if (modulesStore.isAmidAccount) {
          routePermissions.push('is_not_amid_account');
        }
        if (isDeepBoxAccount.value) {
          routePermissions.push('is_not_deepbox_account');
        }
        if (!hasTaxableTaxTimelines.value) {
          routePermissions.push('has_taxable_tax_timelines');
        }
        if (!hasNonFlatTaxTimelines.value) {
          routePermissions.push('has_non_flat_tax_timelines');
        }
        break;
      case 'in-and-out':
      case 'employee':
        if (!isEmployee.value) {
          routePermissions.push('is_employee');
        }
        break;
      case 'employee-groups':
      case 'employees':
        if (!hasFullAccess.value) {
          routePermissions.push('is_admin_group');
        }
        break;
      case 'account-statement':
      case 'journal':
      case 'deepv':
      case 'payment-systems':
        if (isDeepBoxAccount.value) {
          routePermissions.push('is_not_deepbox_account');
        }
        break;
      // no default
    }
    return routePermissions;
  };

  const hasPermission = (neededPermission: Permission) => isOwner.value
    || (!!getEntity.entity.value && !!getEntity.entity.value[neededPermission]);

  const hasPermissions = (neededPermissions: Array<Permission>) => isOwner.value || neededPermissions.every(
    (permission) => hasPermission(permission),
  );

  const hasPermissionsOr = (neededPermissions: Array<Permission>) => isOwner.value || neededPermissions.some(
    (permission) => hasPermission(permission),
  );

  const hasRoutePermission = (route: string) => {
    if (hasFullAccess.value
      && !amidRoutes.includes(route)
      && !deepBoxRoutes.includes(route)
      && !flexibleAccountingPlanRoutes.includes(route)
    ) {
      return true;
    }

    switch (route) {
      case 'in-and-out':
      case 'employee':
        return isEmployee.value;
      case 'employees':
      case 'employee-groups':
        return false;
      case 'accounting':
        return isAccountingPermitted.value;
      case 'accounting-report':
      case 'open-items':
        return hasFrameworkKmu.value || hasFlexibleAccountingPlan.value;
      case 'balance-sheet':
      case 'opening-balance':
      case 'income-statement':
        return (hasFrameworkKmu.value || hasFlexibleAccountingPlan.value)
          && !modulesStore.isAmidAccount;
      case 'key-figures':
        return hasFrameworkKmu.value
          && !modulesStore.isAmidAccount
          && isSwissAccount.value;
      case 'accounting-vat-report':
      case 'vat-journal':
      case 'vat-control-report':
        return (hasFrameworkKmu.value || hasFlexibleAccountingPlan.value)
          && !modulesStore.isAmidAccount
          && hasTaxableTaxTimelines.value;
      case 'vat-adjustment':
        return (hasFrameworkKmu.value || hasFlexibleAccountingPlan.value)
          && !modulesStore.isAmidAccount
          && hasTaxableTaxTimelines.value
          && hasNonFlatTaxTimelines.value;
      case 'deepv':
        return !isDeepBoxAccount.value;
      case 'payment-systems':
        return !isDeepBoxAccount.value;
      case 'api-tokens':
      case 'SettingsCompanyDetail':
      case 'SettingsLocalization':
      case 'SettingsPermissionManagement':
      case 'SettingsModules':
        return isAdmin.value;
      case 'SettingsAddress':
        return hasPermission('addresses');
      case 'SettingsProduct':
      case 'SettingsUnit':
        return hasPermission('products');
      case 'SettingsImportExport':
        return hasPermissionsOr(['products', 'addresses', 'customers', 'payments']);
      case 'SettingsDocumentDesign':
      case 'SettingsQuote':
      case 'SettingsContractNote':
      case 'SettingsDeliveryNote':
      case 'SettingsInvoice':
      case 'SettingsCreditNote':
      case 'SettingsMails':
        return hasPermission('customers');
      case 'SettingsReceipt':
      case 'SettingsSupplierCreditNote':
        return hasPermission('vendors');
      case 'SettingsTax':
      case 'SettingsCurrencies':
      case 'SettingsJournalYears':
      case 'settings-account-plan':
      case 'settings-account-proposals':
        return hasPermission('accounting');
      case 'abasalary-mappings':
        return hasFlexibleAccountingPlan.value;
      case 'merchant-category-codes':
        return hasFlexibleAccountingPlan.value
          || betaFeaturesStore.hasAddressCriteria('MCC')
          || betaFeaturesStore.hasAddressCriteria('MCG');
      case 'SettingsKeyFigures':
        return hasPermission('accounting') && !isDeepBoxAccount.value;
      case 'SettingsTimeTrackingActivityTypesList':
      case 'SettingsTimeTrackingWorkModelsList':
      case 'SettingsTimeTrackingHolidayCalendarsList':
        return hasPermission('time');
      default:
        return true;
    }
  };

  const hasTopLevelRoutePermission = (
    topLevelRoutePermission: TopLevelRoutePermission,
  ) => hasFullAccess.value || hasPermission(topLevelRoutePermission);

  return {
    ...getEntity,
    hasTopLevelRoutePermission,
    hasRoutePermission,
    hasPermission,
    hasPermissions,
    hasPermissionsOr,
    hasFullAccess,
    missingPermissions,
    grantedPermissions,
    hasFlexibleAccountingPlan,
    isAdmin,
    isDeepBoxAccount,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePermissionsStore, import.meta.hot));
}
