<template>
  <v-app id="app">
    <v-container
      :fluid="$vuetify.breakpoint.lgAndDown"
      class="pa-0 app-container"
    >
      <navigation
        v-if="isUserReady && isPermissionsReady && isAccountReady"
        :account="account"
        :inbox-count="inboxCount"
        :is-account-ready="isAccountReady"
        :logout="logout"
        :navigation="navigation"
        :permissions="permissions"
        class="no-print"
      />

      <v-main :class="{ 'pb-10': !$vuetify.breakpoint.smAndUp }" class="ml-3">
        <page-loader
          v-if="showPageLoader"
        />
        <router-view v-else />
      </v-main>
    </v-container>

    <toolbar
      v-if="isUserReady && isPermissionsReady"
      :account="account"
      :inbox-count="inboxCount"
      :is-account-ready="isAccountReady"
      :is-deepbox-account="account.is_deepbox_account"
      :logout="logout"
      :navigation="navigation"
      :permissions="permissions"
      :style="{
        zIndex: $vuetify.breakpoint.xsOnly ? 'unset' : 6,
      }"
      :user="user"
      class="no-print"
    />
    <version-check />
    <verify-email-alert v-if="isSwiss21Account" />

    <app-notifications />
    <app-needs-paid-subscription />

    <v-snackbar
      v-model="messageState.active"
      :color="messageState.color"
      :timeout="3000"
      :top="messageState.top"
    >
      <span v-if="messageState.key">{{ $t(`texts.${messageState.key}`) }}</span>
      <span v-if="messageState.text" style="display:block" v-html="$options.filters.nl2br(messageState.text)"></span>
    </v-snackbar>

    <session-expired />

    <notifications v-if="isUserReady" />

    <div
      :class="{
        'fab-container': true,
        'fab-right': $vuetify.breakpoint.smAndDown,
      }"
    >
      <v-btn
        :style="{ zIndex: 1001 }"
        color="primary"
        :to="{ name: 'support' }"
        :large="!$vuetify.breakpoint.smAndDown"
        :rounded="!$vuetify.breakpoint.smAndDown"
        :fab="$vuetify.breakpoint.smAndDown"
      >
        <v-icon
          :left="!$vuetify.breakpoint.smAndDown"
        >
          mdi-help-circle-outline
        </v-icon>

        <template v-if="!$vuetify.breakpoint.smAndDown">
          {{ $tc('texts.dashboard_help') }}
        </template>
      </v-btn>
    </div>
  </v-app>
</template>

<script>
import Navigation from '@/components/app-layout/Navigation.vue';
import Toolbar from '@/components/app-layout/Toolbar.vue';
import AppNeedsPaidSubscription from '@/components/AppNeedsPaidSubscription.vue';
import AppNotifications from '@/components/AppNotifications.vue';
import Notifications from '@/components/dialog/Notifications.vue';
import SessionExpired from '@/components/dialog/SessionExpired.vue';
import VersionCheck from '@/components/dialog/VersionCheck.vue';
import { useTheme } from '@/composables/theme';
import { usePermissionsStore } from '@/stores/definitions/permissions';
import { useUnreadInboxCountStore } from '@/stores/definitions/unread-inbox-count';
import { useUserValueDisplayOptionsStore } from '@/stores/user-value/display-options';
import { useUserStore } from '@/stores/users/user';
import * as Sentry from '@sentry/vue';
import { mapActions, mapState } from 'pinia';
import { createNamespacedHelpers } from 'vuex';
import VerifyEmailAlert from './components/VerifyEmailAlert.vue';

const { mapState: mapDefinitionsState } = createNamespacedHelpers('definitions');
const {
  mapState: mapDefinitionsAccountState,
  mapGetters: mapDefinitionsAccountGetters,
} = createNamespacedHelpers('definitions/account');
const { mapActions: mapUserActions } = createNamespacedHelpers('user');

const INBOX_COUNT_REFRESH_PERIOD = 300_000;

export default {
  name: 'App',
  components: {
    AppNeedsPaidSubscription,
    AppNotifications,
    Toolbar,
    Navigation,
    SessionExpired,
    Notifications,
    VersionCheck,
    VerifyEmailAlert,
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
  setup() {
    const { isDarkTheme } = useTheme();

    const userValueDisplayOptionsStore = useUserValueDisplayOptionsStore();

    return {
      isDarkTheme,
      userValueDisplayOptionsStore,
    };
  },
  data() {
    return {
      title: 'AbaNinja',
      refreshInterval: null,
      navigation: {
        open: null,
        mini: false,
      },
    };
  },
  computed: {
    ...mapDefinitionsAccountState(['account', 'isLoadingAccount']),
    ...mapDefinitionsAccountGetters(['isSwiss21Account']),
    ...mapDefinitionsState(['isLoggedIn']),
    ...mapState(usePermissionsStore, {
      permissions: 'entity',
      isLoadingPermissions: 'isLoading',
    }),
    ...mapState(useUserStore, {
      user: 'entity',
      isLoadingUser: 'isLoading',
    }),
    ...mapState(useUnreadInboxCountStore, {
      inboxCount: 'entity',
    }),
    messageState() {
      return this.$store.state.messages;
    },
    showPageLoader() {
      return !this.isUserReady
        || this.isLoadingAccount
        || !this.isPermissionsReady
        || this.userValueDisplayOptionsStore.isLoading;
    },
    isAccountReady() {
      if (this.account) {
        const isAccountNotReady = !this.account.has_wizard_completed
          || this.account.needs_confirmation
          || this.account.needs_acc_framework_update
          || (!this.account.has_all_agb_accepted)
          || (!this.account.is_deepbox_account && !this.account.is_swiss21_account);
        return !isAccountNotReady;
      }

      return this.isLoadingAccount;
    },
    isUserReady() {
      return !this.isLoadingUser && !!this.user;
    },
    isPermissionsReady() {
      return !this.isLoadingPermissions && !!this.permissions;
    },

    href() {
      const url = new URL('/support', import.meta.env.VITE_SWISS21_PORTAL_FRONTEND_URL);

      if (this.account?.uuid) {
        url.searchParams.append('uuid', this.account.uuid);
      }

      return url.href;
    },
  },
  watch: {
    $route(to) {
      this.setTitle(to);
    },
    isAccountReady() {
      if (this.isAccountReady) {
        this.initializeApp();
      }
    },
    isDarkTheme: {
      handler(value) {
        this.$vuetify.theme.dark = value;
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval);
  },
  methods: {
    ...mapUserActions(['logout']),
    ...mapActions(useUnreadInboxCountStore, {
      getUnreadInboxCount: 'getEntity',
    }),

    initializeApp() {
      if (!this.account.uuid) {
        return;
      }
      if (this.isLoggedIn) {
        this.getUnreadInboxCount().catch(() => {});
      }

      this.userValueDisplayOptionsStore.requireEntity();

      this.refreshInterval = setInterval(() => {
        if (this.isLoggedIn) {
          this.getUnreadInboxCount().catch(() => {});
        } else {
          clearInterval(this.refreshInterval);
        }
      }, INBOX_COUNT_REFRESH_PERIOD);
      if (
        this.user
        && this.account
        && this.user.first_name
        && this.user.last_name
        && this.user.email
        && this.account.name
      ) {
        if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
          Sentry.setUser({
            id: this.user.uuid,
          });
          Sentry.setContext('account', {
            uuid: this.account.uuid,
          });
        }
      }

      let locale = navigator.language.slice(0, 2);

      if (!locale || locale === 'en') {
        locale = 'en-gb';
      }

      this.setTitle(this.$route);
    },
    setTitle(to) {
      const appendTitle = this.account.name ? `AbaNinja - ${this.account.name}` : 'AbaNinja';
      const title = to.meta.title || to.params.typePlural || null;
      if (title) {
        const translatedTitle = this.$tc(`texts.${title}`);
        this.title = `${translatedTitle} | ${appendTitle}`;
      } else {
        this.title = appendTitle;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "vuetify/src/styles/styles";

.fab-container{
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 10000;
}
.fab-right{
  right: 20px;
  left: unset;
}

@media only screen and (min-width: 2092px) {
  .fab-container{
    left: calc((100vw - 2050px) /2);
  }
}

@media print {
  main {
    padding: 0 !important;
  }
}

.app-container {
  position: relative;
  min-height: 100%;
  @media #{map-get($display-breakpoints, "xl-only")} {
    max-width: 2050px;
  }
}
</style>
