<template>
  <v-menu
    :max-width="$vuetify.breakpoint.xsOnly ? 220 : 250"
    :min-width="$vuetify.breakpoint.xsOnly ? 220 : 250"
    :nudge-top="-50"
    fixed
    left
  >
    <template #activator="{ on, attrs }">
      <v-avatar
        v-bind="attrs"
        :color="props.account.logo ? 'transparent' : props.account.avatar_color"
        size="40"
        v-on="on"
      >
        <img
          v-if="props.account.logo"
          :src="props.account.logo"
          alt=""
          class="avatar-image"
        >
        <span v-else class="white--text text-h4">
          {{ account.initials }}
        </span>
      </v-avatar>
    </template>
    <v-list>
      <v-list-item
        v-if="account.id > 0"
        :to="{ name: 'companies-switch' }"
        class="toolbar-nav__account-switch"
        two-line
      >
        <v-list-item-avatar :color="props.account.logo ? '#ffffff' : props.account.avatar_color">
          <img
            v-if="props.account.logo"
            :src="props.account.logo"
            alt=""
            class="avatar-image"
          >
          <span v-else class="title text--white white--text" style="font-size: 22px;">
            {{ props.account.initials }}
          </span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ props.account.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('texts.switch_account') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isAccountReady" :to="{ name: props.permissions.settings ? 'SettingsList' : 'SettingsUser' }">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('texts.settings') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />
      <v-menu :close-delay="60" :close-on-content-click="false" left offset-x open-on-hover>
        <template #activator="{ attrs, on }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-content>
              <v-list-item-title>{{ $t('texts.designer_setting_display') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
        <v-card>
          <theme-list />
          <template v-if="permissions.settings">
            <v-divider />
            <v-card-actions class="py-3">
              <v-btn :to="{ name: 'settings-general-display' }" block color="primary" exact text>
                {{ $t('texts.more_settings') }}
              </v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-menu>

      <v-divider />

      <v-list-item :href="idpSelfserviceUrl">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('texts.user_data') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'support' }">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('texts.support') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :href="knowledgeBaseUrl" rel="noopener noreferrer" target="_blank">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('texts.knowledge_base') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list-item class="error--text" @click="logout">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('texts.logout') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import type { Permissions } from '@/api/interfaces/definitions/permission';
import type { Account } from '@/api/interfaces/user/account';
import ThemeList from '@/components/Theme/ThemeList.vue';
import { computed, ref } from 'vue';

interface Props {
  account: Account;
  isAccountReady?: boolean;
  permissions: Permissions;
  logout: () => void;
}

const props = defineProps<Props>();

const idpSelfserviceUrl = props.account.is_deepbox_account
  ? ref(import.meta.env.VITE_DEEPSSO_SELFSERVICE_URL) : ref(import.meta.env.VITE_ABASKYIDP_SELFSERVICE_URL);

const knowledgeBaseUrl = computed<string>(() => {
  let language;
  switch (props.account.language_id) {
    case 1:
      language = 'en-gb';
      break;
    case 2:
      language = 'it';
      break;
    case 3:
      language = 'de';
      break;
    case 4:
      language = 'fr';
      break;
    default:
      language = 'de';
  }

  if (props.account.is_deepbox_account) {
    return `${import.meta.env.VITE_HELPDESK_URL}/${language}/categories/360000382891-21-AbaNinja`;
  }

  return import.meta.env.VITE_ABANINJA_KB_URL.replaceAll('{lang}', language);
});

</script>
<style lang="scss" scoped>
.avatar-image{
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 50%;
}
</style>
