<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    :clipped-right="$vuetify.breakpoint.lgAndUp"
    app
    class="abaninja__toolbar"
    fixed
    flat
  >
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" class="pa-0 fill-height toolbar-container">
      <v-app-bar-nav-icon
        v-if="isAccountReady && $vuetify.breakpoint.mdAndDown"
        @click.stop="navigation.open = !navigation.open"
      />
      <app-switch v-if="$vuetify.breakpoint.smAndUp && isAccountReady" btn-class="mr-1" />
      <v-toolbar-title :class="{ 'ml-4': !(isAccountReady) }">
        <router-link :to="{ name: isAccountReady ? 'dashboard' : '' }">
          <img :alt="$tc('texts.abaninja')" :src="logoPath" class="d-block" :height="logoHeight">
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <v-chip
        v-if="showEnvironmentChip && !hasAddressCriteria('HIDE_ENVIRONMENT_CHIP')"
        :color="environmentChip.color"
        class="mr-3"
        close
        label
        @click:close="showEnvironmentChip = false"
      >
        {{ environmentChip.text }}
      </v-chip>
      <app-switch v-if="$vuetify.breakpoint.xsOnly && isAccountReady" />
      <toolbar-actions
        v-if="$vuetify.breakpoint.smAndUp"
        :account="account"
        :inbox-count="inboxCount"
        :is-account-ready="isAccountReady"
        :logout="logout"
        :permissions="permissions"
      />
    </v-container>
  </v-app-bar>
</template>

<script>
import AppSwitch from '@/components/swiss21/AppSwitch.vue';
import { mapActions, mapState } from 'pinia';
import { useBetaFeaturesStore } from '@/stores/definitions/beta-features';
import { createNamespacedHelpers } from 'vuex';
import ToolbarActions from './ToolbarActions.vue';

const { mapActions: mapMessagesActions } = createNamespacedHelpers('messages');

export default {
  components: {
    AppSwitch,
    ToolbarActions,
  },
  props: {
    navigation: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    account: {
      type: Object,
      default: () => {},
    },
    permissions: {
      type: Object,
      default: () => {},
    },
    isAccountReady: {
      type: Boolean,
      default: false,
    },
    isDeepboxAccount: {
      type: Boolean,
      default: false,
    },
    inboxCount: {
      type: Number,
      default: 0,
    },
    logout: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showEnvironmentChip: import.meta.env.VITE_ENVIRONMENT !== 'prod',
    };
  },
  computed: {
    ...mapState(useBetaFeaturesStore, {
      isLoadingBetaFeatures: 'isLoading',
    }),
    logoPath() {
      if (this.$vuetify.theme.dark && this.isDeepboxAccount) {
        return '/images/lettermark-deepninja-darkmode.svg';
      }
      if (this.isDeepboxAccount) {
        return '/images/lettermark-deepninja.svg';
      }

      if (this.$vuetify.theme.dark) {
        return '/images/swiss21/lettermark-swiss21-abaninja-darkmode.svg';
      }
      return '/images/swiss21/lettermark-swiss21-abaninja.svg';
    },
    logoHeight() {
      return this.isDeepboxAccount ? 24 : 18;
    },
    environmentChip() {
      const colors = {
        local: 'success',
        dev: 'warning',
        int: 'error',
      };

      return {
        text: import.meta.env.VITE_ENVIRONMENT.toUpperCase(),
        color: colors[import.meta.env.VITE_ENVIRONMENT],
      };
    },
  },
  created() {
    this.getBetaFeatures().catch((error) => {
      const message = error.data ? error.data.message : error.message;
      if (message) {
        this.errorMessage({ text: message });
      }
    });
  },
  methods: {
    ...mapActions(useBetaFeaturesStore, {
      hasAddressCriteria: 'hasAddressCriteria',
      getBetaFeatures: 'requireEntities',
    }),
    ...mapMessagesActions([
      'errorMessage',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";
@import "vuetify/src/styles/styles";

.abaninja__toolbar {
  .theme--light & {
    border-bottom: 1px solid map-get($material-light, "dividers") !important;
  }

  .theme--dark & {
    border-bottom: 1px solid map-get($material-dark, "dividers") !important;
  }
}

.toolbar-container {
  @media #{map-get($display-breakpoints, "xl-only")} {
    max-width: 2050px;
  }
}
</style>
