export default [
  {
    path: '/supportinfo',
    name: 'supportinfo',
    alias: ['/account-info'],
    redirect: { path: '/v3/support/info' },
  },
  {
    path: '/support',
    name: 'support',
    redirect: { path: '/v3/support' },
  },
];
